import React, { useState, useEffect } from 'react'

const useMultiAudio = (urls, toggleChanged) => {
  const [sources] = useState(
    urls.map(url => {
      return {
        url,
        audio: new Audio(url),
      }
    }),
  )

  const [players, setPlayers] = useState(
    urls.map(url => {
      return {
        url,
        playing: false,
      }
    }),
  )

  const toggle = targetIndex => () => {
    const newPlayers = [...players]
    const currentIndex = players.findIndex(p => p.playing === true)
    if (currentIndex !== -1 && currentIndex !== targetIndex) {
      newPlayers[currentIndex].playing = false
      newPlayers[targetIndex].playing = true
    } else if (currentIndex !== -1) {
      newPlayers[targetIndex].playing = false
    } else {
      newPlayers[targetIndex].playing = true
    }
    toggleChanged(newPlayers[targetIndex].playing)
    setPlayers(newPlayers)
  }

  useEffect(() => {
    sources.forEach((source, i) => {
      players[i].playing ? source.audio.play() : source.audio.pause()
    })

  }, [sources, players])

  useEffect(() => {
    sources.forEach((source, i) => {
      source.audio.addEventListener('ended', () => {
        const newPlayers = [...players]
        newPlayers[i].playing = false
        setPlayers(newPlayers)
      })
    })
    toggle(0);
    return () => {
      sources.forEach((source, i) => {
        source.audio.removeEventListener('ended', () => {
          const newPlayers = [...players]
          newPlayers[i].playing = false
          setPlayers(newPlayers)
        })
      })
    }
  }, [])

  return [players, toggle]
}

const MultiPlayer = ({ urls, togglePlaying }) => {
  const [players, toggle] = useMultiAudio(urls, togglePlaying)
  const togglePlayer = () => {
    toggle(0)
  }

  return (
    <div className="d-flex align-items-left flex-column h-100">
      {/* {players.map((player, i) => (
        <Player key={i} player={player} toggle={()=> { toggle(i) }} />
      ))} */}
      <Player key="0" player={players[0]} toggle={toggle(0)} />
    </div>
  )
}

const Player = ({ player, toggle }) => (
  <div className="mt-auto cricket-play-control">
    <div hidden={player.playing} onClick={toggle}>
      <svg className="play-control" width="18px" height="20px" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg"><g fill="currentcolor"><path d="M17.29,9.02 C18.25,9.56 18.25,10.44 17.29,10.98 L1.74,19.78 C0.78,20.33 0,19.87 0,18.76 L0,1.24 C0,0.13 0.78,-0.32 1.74,0.22 L17.29,9.02 Z"></path></g></svg>
    </div>
    <div hidden={!player.playing} onClick={toggle}>
      <svg className="play-control" width="18px" height="20px" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg"><g fill="currentcolor"><path d="m0,0.99c0,-0.55 0.45,-0.99 0.99,-0.99l4.02,0c0.55,0 0.99,0.46 0.99,0.99l0,18.02c0,0.55 -0.45,0.99 -0.99,0.99l-4.02,0c-0.55,0 -0.99,-0.46 -0.99,-0.99l0,-18.02zm10,0c0,-0.55 0.45,-0.99 0.99,-0.99l4.02,0c0.55,0 0.99,0.46 0.99,0.99l0,8.02c0,10.55 -0.45,10.99 -0.99,10.99l-4.02,0c-0.55,0 -0.99,-0.46 -0.99,-0.99l0,-18.02z"></path></g></svg>
    </div>
  </div>
)


export default MultiPlayer