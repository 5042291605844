import './App.css'
import cricket from './assets/mickey.gif'
import crossBigB from './assets/cross-big.svg'
import openLink from './assets/open-link-w.svg'
import metamask from './assets/metamask.svg'
import walletConnect from './assets/walletc.svg'
import Web3 from 'web3'
import detectEthereumProvider from '@metamask/detect-provider';
import { mintPublic, mintPublicInk, getTokenBalance, getBalance, getTotalSupply, getINKBalance, subscribeMintEvent, ParseFloat, getAllowanceInk, setAllowanceInk } from "./model/Blockchain"
import { useEffect, useState, useCallback } from 'react'
import { Navbar, Container, Row, Col, Button, Alert, Form, InputGroup, Modal, Spinner } from 'react-bootstrap'
import WalletConnectProvider from "@walletconnect/web3-provider";
import logo from './assets/white_logo.png'
import MultiPlayer from './components/Multiplayer'

import coinbaseWallet from "./assets/coibaseWallet.webp";

import WalletLink from "walletlink";
const MINT_COUNT = 50;

function App() {
  const [ethBalance, setEthBalance] = useState('Ξ')
  const [currentAccount, setCurrentAccount] = useState('')
  const [isLogged, setIsLogged] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [modalTrustedShow, setModalTrustedShow] = useState(false);
  const [modalConnectShow, setModalConnectShow] = useState(false);
  const [minted, setMinted] = useState(0);
  const [balance, setBalance] = useState(0);
  const [balanceEth, setBalanceEth] = useState(0);
  const [flowtyBalance, setFlowtyBalance] = useState(0);
  const [flowtyBalanceInt, setFlowtyBalanceInt] = useState(0);
  const [maxPresale, setMaxPresale] = useState(50);
  const [salePrice, setSalePrice] = useState(10);
  const [saleState, setSaleState] = useState('sale');
  const [_web3, setWeb3] = useState();
  const [currentProvider, setProvider] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  let isMobile = width <= 768;
  const [inkBalance, setINKBalance] = useState(0);
  const [waitApprove, setWaitApprove] = useState(false);
  const [waitMint, setWaitMint] = useState(false);
  const [allowance, setAllowance] = useState(0);
  const [playing, setPlaying] = useState(true);

  const setAllowanceInkArt = () => {
    setWaitApprove(true);
    const web3 = new Web3(new Web3.providers.HttpProvider('https://eth-mainnet.alchemyapi.io/v2/8prrk8tEp2P60GxObZK7a7SAznxfvJma'));

    setAllowanceInk(isMobile, _web3, currentAccount, 1000000, () => {
      setWaitApprove(false);
      setAllowance(100000);
    });
  };

  async function getEthBalance(walletAddress) {
    try {
      const web3 = new Web3(new Web3.providers.HttpProvider('https://eth-mainnet.alchemyapi.io/v2/8prrk8tEp2P60GxObZK7a7SAznxfvJma'));
      const data = await getAllowanceInk(walletAddress);
      setAllowance(data);
      // const web3 = new Web3(new Web3.providers.HttpProvider('https://eth-rinkeby.alchemyapi.io/v2/QLZIvB2SyM8OYRQ5Vf63tMH71_-sXFQz'));
      var balance = await web3.eth.getBalance(walletAddress);
      console.log(web3.utils.fromWei(balance, "ether"));
      // balance = web3.toDecimal(balance);
      const _inkBalance = await getINKBalance(walletAddress);
      setINKBalance(ParseFloat(_inkBalance, 2));
      setEthBalance(`${parseFloat(web3.utils.fromWei(balance, "ether")).toFixed(3)} Ξ / ${ParseFloat(_inkBalance, 2)} INK`);
      setBalanceEth(web3.utils.fromWei(balance, "ether"));
    } catch { }
  }

  const SignIn = useCallback(async () => {
    setModalConnectShow(true)
  }, [])

  const connectWalletC = async () => {
    setModalConnectShow(false)
    const provider = new WalletConnectProvider({
      infuraId: "faee323368cd418eb194837ae9a138c3",
    });
    try {
      await provider.enable();
      setProvider(provider);
      await connectEth(provider, false);
    } catch (e) {
      console.log(e)
    }
  };

  const handleAccountsChanged = async (accounts) => {
    //if(!isLogged) return
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      setMessage(messages => [...messages, { head: "User Rejected Request", body: 'Please connect to MetaMask.', variant: 'info' }])
    } else if (accounts[0] !== currentAccount) {
      setCurrentAccount(accounts[0])
      await getEthBalance(accounts[0])
      // setMessage(messages => [...messages, {head : "👋 You are logged in", body: `Address: ${accounts[0]}`, variant: 'warning'}])
    }
  }

  useEffect(() => {
    const now = new Date().getTime();
    window.onbeforeunload = function () { return "Prevent reload" }

    // const newImage = new Image();
    // newImage.src = './assets/bg.jpeg';
    // window['./assets/bg.jpeg'] = newImage;
    // if (window.ethereum) {
    //   window.ethereum.on('accountsChanged', handleAccountsChanged);
    // }
    try {
      // subscribeMintEvent((id) => {
      //   setMinted(`${parseInt(id) + 1}`.toString());
      // })
    } catch (err) { console.log(err) }
    // window.ethereum.on('chainChanged', (_chainId) => {
    //   console.log(_chainId);
    //   setCurrentChainID(() => parseInt(_chainId, 16))
    //   //window.location.reload()
    // });
  }, []);

  const SignOut = async () => {
    setIsLogged(false)
    setCurrentAccount('');
    setEthBalance('Ξ');
    try { await currentProvider.disconnect() } catch { }
  }

  const shortAddr = () => {
    return `${currentAccount.substr(0, 4)}...${currentAccount.substring(currentAccount.length - 4, currentAccount.length)}`
  }
  const [messages, setMessage] = useState([])

  const Message = (props) => {
    const [show, setShow] = useState(true);

    const close = () => {
      setShow(false)
      setMessage(messages.filter((item, index) => index !== props.id))
    }

    useEffect(() => {
      // when the component is mounted, the alert is displayed for 3 seconds
      // if (!props.nohide) {
      //   setTimeout(() => {
      //     close();
      //   }, 3000);
      // }
    }, []);

    if (show) {
      return (
        <Alert className="flowty-alert" onClose={close} dismissible>
          <Alert.Heading>{props.head}</Alert.Heading>
          <p className="alert-flowty">
            {props.body} <a target="_blank" rel="noreferrer" href={props.url} role="button" tabIndex="0" className="alert-link-soy">{props.urlText1} <img alt="" hidden={!!!props.urlText1} style={{ transition: "none" }} src={openLink} className="external-link" /></a>
          </p>
          <hr hidden={!!!props.info1} />
          <p hidden={!!!props.info1} className="mb-0 flowty-alert-sub">
            {props.info1} <a target="_blank" rel="noreferrer" href={props.url2} role="button" tabIndex="0" className="alert-link-soy">{props.urlText2} <img alt="" style={{ transition: "none" }} src={openLink} className="external-link" /></a>
          </p>
          <p hidden={!!!props.info2} className="mb-0 flowty-alert-sub">
            {props.info2}
          </p>
        </Alert>
      )
    } else {
      return (<></>)
    }


  }


  const connectEth = async (provider, isMetamask, skip) => {
    try {
      const web3 = new Web3(provider);
      console.log(`chain`);
      const chain = await web3.eth.getChainId();
      if (chain !== 1) {
        setMessage([
          {
            head: "Wrong network",
            body: `Please connect to Ethereum Main!`,
            variant: "warning",
          },
        ]);
        await SignOut();
        return;
      }
      if (!provider) {
        setMessage([
          {
            head: "Wallet not found",
            body: `Please install the MetaMask!`,
            variant: "warning",
          },
        ]);
      } else {
        const address = await ConnectWallet(web3, isMetamask);
        if (!!address) {
          setMessage((messages) => [
            ...messages.filter((el) => el.body !== `Address: ${address}`),
            {
              head: "👋 You are logged in",
              body: `Address: ${address}`,
              variant: "success",
            },
          ]);
          setCurrentAccount(address);
          const [supply, balance] = await Promise.all([getTotalSupply(web3), getBalance(address, web3), getEthBalance(address)]);
          setMinted(supply);
          setBalance(balance)
          setWeb3(web3)
          window.ethereum.on("accountsChanged", handleAccountsChanged);
        }
      }
    } catch (err) {
      // Sentry.captureException(err);
    }
  };

  const connectMetamask = async () => {
    setModalConnectShow(false);
    const provider = await detectEthereumProvider();
    setProvider(provider);
    await connectEth(provider, true);
  };

  const connectWalletConnect = async () => {
    setModalConnectShow(false);
    const provider = new WalletConnectProvider({
      infuraId: "2c9f24ade5554e4a84e0c8cd4c8c1877",
    });
    try {
      await provider.enable();
      setProvider(provider);
      await connectEth(provider, false);
    } catch (e) {
      console.error(e);
    }
  };

  const connectWalletLink = async () => {
    setModalConnectShow(false);
    const walletLink = new WalletLink({
      appName: "Flowtys Studio",
      appLogoUrl: "/favicon.ico",
      darkMode: true,
    });

    // Initialize a Web3 Provider object
    const provider = walletLink.makeWeb3Provider(
      `https://mainnet.infura.io/v3/2c9f24ade5554e4a84e0c8cd4c8c1877`,
      1
    );

    try {
      setProvider(provider);
      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });
      setIsLogged(true);
      setCurrentAccount(accounts[0]);
      const web3 = new Web3(provider);
      const [supply, balance] = await Promise.all([getTotalSupply(web3), getBalance(accounts[0], web3), getEthBalance(accounts[0])]);
      setMinted(supply);
      setBalance(balance)
      setWeb3(web3)
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    } catch (e) {
      console.error(e);
    }
  };

  const ConnectWallet = async (web3, isMetamask, skip) => {
    try {
      const accounts = isMetamask
        ? await window.ethereum.request({ method: "eth_requestAccounts" })
        : await web3.eth.getAccounts();
      setIsLogged(true);
      return accounts[0];
    } catch (err) {
      if (err.code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        console.info("Please connect to MetaMask.");
        setMessage((messages) => [
          ...messages,
          {
            head: "User Rejected Request",
            body: "Please connect to a MetaMask.",
            variant: "info",
          },
        ]);
      } else if (err.code === -32002) {
        console.info("Please unlock MetaMask.");
        setMessage((messages) => [
          ...messages,
          {
            head: "User Request Pending",
            body: "Please unlock a MetaMask and try agin.",
            variant: "info",
          },
        ]);
      } else {
        console.error(err);
        setMessage((messages) => [
          ...messages,
          { head: "Error", body: err.message, variant: "info" },
        ]);
      }
    }
  };
  function ConectModal(props) {
    return (
      <Modal
        {...props}
        className="align-items-center justify-content-center"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="trusted-modal"
        centered
      >
        <Modal.Body>
          <Row
            hidden={isMobile}
            className="align-items-center justify-content-center h-100 row-border wallet-connect-hover"
            onClick={connectMetamask}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={metamask}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">MetaMask</div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Connect to your MetaMask Wallet
                </div>
              </Row>
            </Col>
          </Row>
          <Row
            className="align-items-center justify-content-center h-100 wallet-connect-hover row-border"
            onClick={connectWalletConnect}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={walletConnect}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">
                  Wallet Connect
                </div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Connect your wallet ( MetaMask & more)
                </div>
              </Row>
            </Col>
          </Row>
          <Row
            className="align-items-center justify-content-center h-100 wallet-connect-hover"
            onClick={connectWalletLink}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={coinbaseWallet}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">
                  Coinbase Wallet
                </div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Connect your browser or mobile app
                </div>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  const [amount, setAmount] = useState(1)
  const [validated, setValidated] = useState(false);

  function onInputchange(event) {
    setAmount(event.target.value)
  }

  function holderStatus(flowtys) {
    if (flowtys >= 8) {
      return 3
    } else if (flowtys >= 4) {
      return 2
    } else if (flowtys >= 1) {
      return 1
    }
    return 0
  }

  function getPrice(flowtys) {
    return 90;
  }

  function getMaxPresale(flowtys) {
    return 4;
  }

  const mintPressedInk = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false || !amount || amount > 50 || amount < 1) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return
    }
    event.preventDefault();
    setValidated(true);
    // const provider = await detectEthereumProvider()
    const web3 = new Web3(currentProvider);
    setWaitMint(true)

    await mintPublicInk(isMobile, web3, amount, currentAccount, (hash) => {
      console.log(`Balance: ${balance + parseInt(amount)}`)

      setWaitMint(false);
      setEthBalance(`${parseFloat(balanceEth).toFixed(3)} Ξ / ${ParseFloat(inkBalance - amount * 2000, 2)} INK`);
      setINKBalance(inkBalance - amount * 2000);

      setBalance(parseInt(balance) + parseInt(amount))
      setMessage(messages => [...messages, {
        head: "🎉 Mickeys on the way!",
        url: "https://etherscan.io/tx/" + hash,
        url2: "https://opensea.io/account",
        urlText1: "Etherscan",
        urlText2: "Opensea Account",
        body: `You can check the transaction on`,
        info1: 'Your NFT will then appear in your',
        info2: 'This might take a few minutes depending on network load.',
        nohide: true,
        variant: 'dark'
      }])
    });
  };

  const mintPressed = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false || !amount || amount > 50 || amount < 1) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return
    }
    event.preventDefault();
    setValidated(true);
    // const provider = await detectEthereumProvider()
    const web3 = new Web3(currentProvider);
    setWaitMint(true)
    await mintPublic(isMobile, web3, amount, currentAccount, (hash) => {
      console.log(`Balance: ${balance + parseInt(amount)}`)
      setWaitMint(false)

      setBalance(parseInt(balance) + parseInt(amount))
      setMessage(messages => [...messages, {
        head: "🎉 Mickeys on the way!",
        url: "https://etherscan.io/tx/" + hash,
        url2: "https://opensea.io/account",
        urlText1: "Etherscan",
        urlText2: "Opensea Account",
        body: `You can check the transaction on`,
        info1: 'Your NFT will then appear in your',
        info2: 'This might take a few minutes depending on network load.',
        nohide: true,
        variant: 'dark'
      }])
    });
  };


  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setSaleState('sale');
      return "";
    } else {
      // Render a countdown

      return <span>{('00' + hours).slice(-2)}:{('00' + minutes).slice(-2)}:{('00' + seconds).slice(-2)}</span>;
    }
  };
  return (
    <>

      <Navbar className="navbar-expand-md" variant="dark">
        <Navbar.Brand hidden={isMobile} href="#home" className="mx-auto order-0">



        </Navbar.Brand>
        <div className="float-right top-connect-box">
          <Row>
            <Col className=""><div className="balance-top">{ethBalance}</div></Col>
            <Col hidden={isMobile}><Button className="connect-top" disabled={isLogged} onClick={SignIn}>{isLogged ? shortAddr() : "Connect"}</Button>{' '}</Col>
            <Col><img alt="" hidden={!isLogged} src={crossBigB} className="Cross-big-top" onClick={SignOut} /></Col>
          </Row>
        </div>
      </Navbar>

      <div className="message-list mt-5" hidden={isMobile}>
        {
          messages.map((item, i) => (
            <Message head={item.head}
              body={item.body}
              variant={item.variant} id={i} key={i} url={item.url} url2={item.url2}
              urlText1={item.urlText1}
              urlText2={item.urlText2}
              info1={item.info1}
              info2={item.info2}
              nohide={item.nohide} />
          ))
        }
      </div>
      <ConectModal
        show={modalConnectShow}
        connectMetamask={connectMetamask}
        connectWalletC={connectWalletC}
        onHide={() => setModalConnectShow(false)}
      />
      <Container>

        <Row className="h-100 z-high">
          <Col className="align-items-center justify-content-center h-100">
            <Row md="auto" className="justify-content-center align-items-center w-100">
              <Col md="auto">
                <img
                  alt=""
                  src={logo}
                  width="500"
                  height="500"
                  className=""
                />{' '}  </Col>
              <Col className="d-flex align-items-left flex-column h-100">
                <MultiPlayer className="d-flex align-items-left flex-column h-100"
                  urls={[
                    'https://mickey.flowtys.com/willie.mp3',
                  ]}
                  togglePlaying={setPlaying}
                />
              </Col>
              <Col md="auto" hidden={isMobile} className="justify-content-center align-items-center">
                <img src={cricket} alt="loading..." className="mt-auto cricket-image" />
              </Col>

            </Row>
            <Row className="justify-content-center w-100 mb-3">
              <span className='sale-minted'>Check out collection on <a rel="noreferrer" target="_blank" href="https://opensea.io/collection/mememickeys" role="button" tabIndex="0" className="alert-link-flowty"> Opensea <img alt="" style={{ transition: "none" }} src={openLink} className="external-link" /></a></span>
            </Row>

            <Row hidden={isLogged} md="auto" className="justify-content-center w-100 main-box">
              <Button className="main-button" onClick={SignIn}>{isLogged ? shortAddr() : "Connect"}</Button>{' '}
            </Row>
            <Row hidden={isLogged ? (parseInt(minted) === 10000 ? false : true) : true} md="auto" className="justify-content-center w-100 main-box">
              <Col>
                <Row><Button disabled className="main-button" onClick={SignIn}>Sold out</Button>{' '}</Row>
              </Col>
            </Row>


            <Row hidden={!isLogged} className="align-items-center justify-content-center h-100 mb-5 mt-2 main-box">
              <Col hidden={!isLogged} className="align-items-center justify-content-center ">
                <Form noValidate validated={validated} className="">
                  <Row className="justify-content-center w-100 mb-3">
                    <span className='sale-minted'>{minted} / 10000</span>
                  </Row>
                  <Row className="justify-content-center w-100">
                    <InputGroup className="mb-3 sale-input-box align-items-center" size="lg">
                      <Button className="sale-add" onClick={(e) => { e.preventDefault(); setAmount(Math.max(1, amount - 1)); }} >-</Button>

                      <Form.Control
                        disabled className="sale-input" type="number" value={amount} onChange={onInputchange} isInvalid={amount > 50 || amount < 1}
                      />
                      {/*   <input type="text" formControlName="xyz" [readonly]="anyBooleanPropertyFromComponent" /> */}
                      <Button className="sale-add" onClick={(e) => { e.preventDefault(); setAmount(Math.min(amount + 1, 50)); }} >+</Button>
                      <Button className="sale-max" onClick={(e) => { e.preventDefault(); setAmount(50); }} >MAX</Button>
                      <Form.Control.Feedback type="invalid">
                        Maximum {maxPresale} NFT per tx.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Row>
                  <Row md="auto" className="justify-content-center w-100" style={{ transition: "none" }}>
                    <Col sm={4}>
                      <Row className="justify-content-center w-100">
                        <Button onClick={(e) => {
                          e.preventDefault();
                          mintPressed(e);
                        }} disabled={waitMint || isNaN(amount) || amount <= 0 || amount > MINT_COUNT || balanceEth < ((salePrice * amount) / 1000)} className="mint-button"  >
                          <Spinner
                            hidden={!waitMint}
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          {(isNaN(amount) || amount <= 0 || amount > MINT_COUNT ? 'Set Amount' : `Mint ${amount} NFT for ${(salePrice * amount) / 1000} Ξ`)}</Button>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="justify-content-center w-100 mt-3">
                    <span className={isMobile ? 'sale-info-mob' : 'sale-info'}>You can mint for 0.01 Ξ per 1</span>
                  </Row>
                  <Row md="auto" className="justify-content-center w-100" style={{ transition: "none" }}>
                    <Col sm={4}>
                      <Row className="justify-content-center text-center w-100">
                        <span className={isMobile ? 'sale-info-mob text-center' : 'sale-info text-center'}>-- OR --</span>
                      </Row>
                    </Col>  </Row>
                  <Row md="auto" className="justify-content-center w-100" style={{ transition: "none" }}>

                    <Col hidden={allowance === 0} sm={4}>
                      <Row className="justify-content-center w-100">
                        <Button onClick={(e) => {
                          e.preventDefault();
                          mintPressedInk(e);
                        }} disabled={waitMint || isNaN(amount) || amount <= 0 || amount > MINT_COUNT || inkBalance < amount * 2000} className="mint-button" type="submit" >
                          <Spinner
                            hidden={!waitMint}
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          {(isNaN(amount) || amount <= 0 || amount > MINT_COUNT ? 'Set Amount' : `Mint ${amount} NFT for ${amount * 2000} $INK`)}</Button>
                      </Row>
                    </Col>
                    <Col hidden={allowance > 0}>
                      {" "}
                      <Button
                        disabled={waitApprove}
                        className="mint-button mt-4"
                        onClick={setAllowanceInkArt}
                      >
                        <Spinner
                          hidden={!waitApprove}
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Allow $INK
                      </Button>{" "}
                    </Col>
                  </Row>
                  <Row className="justify-content-center w-100 mt-3">
                    <span className={isMobile ? 'sale-info-mob' : 'sale-info'}>You can mint for 2000 $INK per 1</span>
                  </Row>
                  <Row className="align-items-center justify-content-center mt-3 w-100 ">
                    {/* <span className="flowtys-art-price-sub">
                (max 50 per single TX)
              </span> */}
                    <div className="align-bottom text-center">
                      {" "}
                      <span className="flowtys-art-price-sub">Smart Contract: </span>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://etherscan.io/address/0x41c40555BA7F910f07adf7FDaa3a4c940d6DF612"
                        role="button"
                        tabIndex="0"
                        className="alert-link-flowty"
                      >
                        {" "}
                        0x41c40555BA7F910f07adf7FDaa3a4c940d6DF612{" "}
                        <img
                          alt=""
                          style={{ transition: "none" }}
                          src={openLink}
                          className="external-link"
                        />
                      </a>
                    </div>
                  </Row>
                </Form>
              </Col>
            </Row>
            {/* <Row hidden={isMobile} className="justify-content-center align-items-center w-100">
              <img src={cricket} alt="loading..." className="mt-auto cricket-image" />
            </Row> */}
          </Col>
        </Row>

      </Container>
    </>
  );
}

export default App;
