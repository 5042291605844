/* global BigInt */
import Web3 from "web3";
const contractABI = require("../contracts/FlowtysMemeMickey.json");
const contractABIFlowtys = require("../contracts/Flowtys.json");
const contractABIINK = require("../contracts/INK.json");

const contractAddress = "0x41c40555BA7F910f07adf7FDaa3a4c940d6DF612"  // prod: 0x2d0Cfb7C593D5c7000dCe5389d555883648A61c9 rinkeby: "0x579FE3e82F8e21Afb5AE22d31A1c3d442EA91f46"
const contractAddressFlowtys = "0x52607cb9c342821ea41ad265b9bb6a23bea49468" // "0xBFF05A305ECbF9992010B78B65D7b2c16B567D24" // 0x98e96C82f95a225503fC78e20749C9FBc5C4ab14

let web3 = undefined
// let account = undefined
let contract = undefined
// Requires Infura or Alchemy API key
const web3Alchemy = new Web3('wss://mainnet.infura.io/ws/v3/77b1a03f653644c0abd36f7471d8293a') // 'wss://mainnet.infura.io/ws/v3/77b1a03f653644c0abd36f7471d8293a'
// const web3Alchemy = new Web3('wss://eth-rinkeby.alchemyapi.io/v2/QLZIvB2SyM8OYRQ5Vf63tMH71_-sXFQz') // 'wss://mainnet.infura.io/ws/v3/77b1a03f653644c0abd36f7471d8293a'
let subscription = undefined;
const inkAddres = '0xB8BC04A8be09C4734e3B1a6169dcC0a4CD6d5efA';
const pfpAddress = '0x41c40555BA7F910f07adf7FDaa3a4c940d6DF612';

export async function getAllowanceInk(owner) {
  try {
    const contract = new web3Alchemy.eth.Contract(
      contractABIINK.abi,
      inkAddres
    );
    const result = await contract.methods
      .allowance(owner, pfpAddress)
      .call();
    return ParseFloat(parseFloat(result) / parseFloat(10 ** 18), 2);
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function setAllowanceInk(isMobile, web3, owner, amount, onDone) {
  try {
    const contract = new web3.eth.Contract(contractABIINK.abi, inkAddres);
    const tx = {
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      // this could be provider.addresses[0] if it exists
      from: owner,
      // target address, this could be a smart contract address
      to: inkAddres,
      // this encodes the ABI of the method and the arguments
      data: contract.methods
        .increaseAllowance(
          pfpAddress,
          `${BigInt(amount * parseFloat(10 ** 18))}`
        )
        .encodeABI(),
    };
    if (isMobile) {
      try {
        contract.methods.increaseAllowance(pfpAddress,
          `${BigInt(amount * parseFloat(10 ** 18))}`).send({ from: owner }, function (err, res) {
            if (err) {
              console.log(err)
              return
            }
            onDone(res)
          })
      } catch (e) {
        console.log(e)
      }
    } else {
      web3.eth.sendTransaction(tx).on('receipt', onDone).on('error', function (error) { })
    }

  } catch (e) {
    console.log(e);
    return 0;
  }
}

export function isWeb3Ready() {
  return web3 !== undefined
}

export function ParseFloat(str, val) {
  str = str.toString();
  if (str.indexOf(".") === -1) {
    return Number(str);
  }
  str = str.slice(0, str.indexOf(".") + val + 1);
  return Number(str);
}

export async function getINKBalance(address) {
  try {
    const contract = new web3Alchemy.eth.Contract(
      contractABIINK.abi,
      inkAddres
    );
    const result = await contract.methods.balanceOf(address).call();
    return ParseFloat(parseFloat(result) / parseFloat(10 ** 18), 2);
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function mintPublicInk(isMobile, web3, total, accountFrom, onHash) {
  const contract = new web3.eth.Contract(contractABI.abi, contractAddress);
  const tx = {
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
    // this could be provider.addresses[0] if it exists
    from: accountFrom,
    // target address, this could be a smart contract address
    to: contractAddress,
    // optional if you want to specify the gas limit 
    // gas: gasLimit, 
    // number of NFT * price per each
    // this encodes the ABI of the method and the arguments
    // contract.methods.mintAllowList(total, ['0x26c0de9f5897dee17d316c0ea5a6ec852cff5b477ea37d3338d235c42b72b251', '0x72dd0ecc29d05c5291f1d3c64f5650da6a2dbe34d3da85f9ae2e9bccf8b21f64']).encodeABI() 
    data: contract.methods.mintInk(total).encodeABI()
  };
  if (isMobile) {
    try {
      contract.methods.mintInk(total).send({ from: accountFrom }, function (err, res) {
        if (err) {
          console.log(err)
          return
        }
        onHash(res)
      })
    } catch (e) {
      console.log(e)
    }
  } else {
    web3.eth.sendTransaction(tx).on('transactionHash', onHash).on('error', function (error) { })
  }
}


export async function mintPublic(isMobile, web3, total, accountFrom, onHash) {
  const price = 10; //0.01 ETH
  const contract = new web3.eth.Contract(contractABI.abi, contractAddress);
  const tx = {
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
    // this could be provider.addresses[0] if it exists
    from: accountFrom,
    // target address, this could be a smart contract address
    to: contractAddress,
    // optional if you want to specify the gas limit 
    // gas: gasLimit, 
    // number of NFT * price per each
    value: web3.utils.toWei(`${price * total}`, 'finney'),
    // this encodes the ABI of the method and the arguments
    // contract.methods.mintAllowList(total, ['0x26c0de9f5897dee17d316c0ea5a6ec852cff5b477ea37d3338d235c42b72b251', '0x72dd0ecc29d05c5291f1d3c64f5650da6a2dbe34d3da85f9ae2e9bccf8b21f64']).encodeABI() 
    data: contract.methods.mint(total).encodeABI()
  };
  if (isMobile) {
    try {
      contract.methods.mint(total).send({ from: accountFrom, value: web3.utils.toWei(`${price * total}`, 'finney') }, function (err, res) {
        if (err) {
          console.log(err)
          return
        }
        onHash(res)
      })
    } catch (e) {
      console.log(e)
    }
  } else {
    web3.eth.sendTransaction(tx).on('transactionHash', onHash).on('error', function (error) { })
  }
}

export async function getTotalSupply(web3) {
  contract = new web3Alchemy.eth.Contract(contractABI.abi, contractAddress)
  return contract.methods.totalSupply().call();
}

export async function getBalance(address, web3) {
  const contract = new web3Alchemy.eth.Contract(contractABI.abi, contractAddress);
  const balance = await contract.methods.balanceOf(address).call();

  return balance;
}

export async function getBalanceFlowtys(address, web3) {
  const contract = new web3Alchemy.eth.Contract(contractABIFlowtys.abi, contractAddressFlowtys);
  const balance = await contract.methods.balanceOf(address).call();
  return balance;
}

export async function getTokenBalance(web3) {
  contract = new web3Alchemy.eth.Contract(contractABI.abi, contractAddress)
  try {
    const res = await Promise.all([contract.methods.getSupply(3).call(), contract.methods.getSupply(4).call(), contract.methods.getSupply(5).call(), contract.methods.getSupply(6).call(), contract.methods.getSupply(7).call(), contract.methods.getSupply(8).call()]);
    return res;
  } catch (e) {
    console.log(e)
  }
  return [];
}

export async function subscribeMintEvent(callback) {
  contract = new web3Alchemy.eth.Contract(contractABI.abi, contractAddress)
  const eventJsonInterface = web3Alchemy.utils._.find(
    contract._jsonInterface,
    o => o.name === 'Transfer' && o.type === 'event',
  )
  subscription = web3Alchemy.eth.subscribe('logs', {
    address: contract.options.address,
    topics: [eventJsonInterface.signature]
  }, (error, result) => {
    if (!error && web3Alchemy) {
      const eventObj = web3Alchemy.eth.abi.decodeLog(
        eventJsonInterface.inputs,
        result.data,
        result.topics.slice(1)
      )
      console.log(eventObj)
      callback(eventObj && eventObj.tokenId)
    }
  })
}

export async function getTokens(web3, address) {
  contract = new web3Alchemy.eth.Contract(contractABI.abi, contractAddress)
  const balance = await contract.methods.balanceOf(address).call();
  if (balance === 0) {
    return [];
  }
  const tokens = await Promise.all(Array.from({ length: balance - 1 }, (v, k) => k + 1).map(el => contract.methods.tokenOfOwnerByIndex(address, el).call()));
  console.log(tokens);
  return tokens;
}
